import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../config.js";
import dayjs from "dayjs";
import helpers from "../utils/helpers";
import teamBlankLogo from "../images/team-blank-logo.svg";
import _ from "lodash";
import EventData from "./event_details/EventData.js";
export default class GamesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: this.props.article
    };
    this.state = {
      ...this.state,
      ...this.props
    };
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops
      });
    }
  }

  getGames() {
    var article = this.state.article;
    var mainContent = [];
    var expert_details = article.expert_details || {};
    var expert_details_keys = Object.keys(expert_details);
    var expert = expert_details[expert_details_keys[0]] || {};
    for (let desc of article.description) {
      if (typeof desc !== "undefined" && desc !== null) {
        if (
          typeof desc !== "undefined" &&
          desc.type === "deprecated-integration"
        ) {
          var url = desc.url;
          var onlyForFirst = true;

          if (url.games) {
            var totalGames = 0;
            url["games"].map((game, index) => {
              if (game.hasOwnProperty("eventid")) {
                totalGames++;
              }
            });

            var gameCount = 0;
            for (let game of url.games) {
              gameCount++;
              var odds = [];
              var event = article.schibsted_events[game.eventid];
              if (event) {
                for (let bc in event.odds) {
                  odds.push(
                    <div key={game.eventid + bc} className="teaser-match-odds">
                      {/* <div className="column teaser-match-odds-heading">{bc}</div> */}
                      <div className="column teaser-match-odds-heading">
                        ODDS
                      </div>
                      <div className="column">
                        <div className="teaser-match-odds-odds">
                          {/* <a href={helpers.generateBettingUrl(event.odds[bc]["1"]["outcomeid"], event.odds[bc]["1"]["betting_offer_id"], 10)} target="blank"> */}
                          {event.odds[bc]["1"]["value"] &&
                            event.odds[bc]["1"]["value"].toFixed(2)}
                          {/* </a> */}
                        </div>
                        <div className="teaser-match-odds-odds">
                          {/* <a href={helpers.generateBettingUrl(event.odds[bc]["1"]["outcomeid"], event.odds[bc]["1"]["betting_offer_id"], 10)} target="blank"> */}
                          {event.odds[bc]["X"]["value"] &&
                            event.odds[bc]["X"]["value"].toFixed(2)}
                          {/* </a> */}
                        </div>
                        <div className="teaser-match-odds-odds">
                          {/* <a href={helpers.generateBettingUrl(event.odds[bc]["1"]["outcomeid"], event.odds[bc]["1"]["betting_offer_id"], 10)} target="blank"> */}
                          {event.odds[bc]["2"]["value"] &&
                            event.odds[bc]["2"]["value"].toFixed(2)}
                          {/* </a> */}
                        </div>
                      </div>
                      <div className="column"></div>
                    </div>
                  );
                }
                var team_info = event.team_info;
                var team_info_Keys = Object.keys(team_info);
                var teamOneName = game["team-a"];
                var teamTwoName = game["team-b"];
                var team1Img =
                  team_info[game["team_a_participant_id"]] &&
                  team_info[game["team_a_participant_id"]].logo;
                team1Img = team1Img
                  ? team1Img + "?rule=clip-32x32"
                  : teamBlankLogo;
                var team2Img =
                  team_info[game["team_b_participant_id"]] &&
                  team_info[game["team_b_participant_id"]].logo;
                team2Img = team2Img
                  ? team2Img + "?rule=clip-32x32"
                  : teamBlankLogo;
                var evenTime = dayjs(event.start_date)
                  .locale("sv")
                  .format("HH:mm");
                var tagContent = "";
                var template = "";
                if (url.template != undefined && url.template.length > 0) {
                  tagContent = url.template;
                  if (tagContent.toLowerCase() === "flexible") {
                    tagContent = url.tags[0];
                  }
                  template = tagContent;
                } else {
                  tagContent = "Dagens avslag";
                }
                var gameSuggestion = [];
                if (game["type"]) {
                  gameSuggestion.push(
                    <div
                      key={"type-" + game["type"]}
                      className="game-spel-tips"
                    >
                      SPELTYP: <strong>{game["type"].toUpperCase()}</strong>{"  "}
                      {_.has(game,"betting-odds") && <span style={{marginLeft : "5px"}}>ODDS: <strong>{game["betting-odds"]}</strong></span>}
                    </div>
                  );
                } else {
                  gameSuggestion.push(
                    <div
                      key={"type-" + game["type"]}
                      className="game-spel-tips"
                    >
                      SPELTYP: <strong>{"1X2"}</strong>{" "}
                      {_.has(game,"betting-odds") && <span style={{marginLeft : "5px"}}>ODDS: <strong>{game["betting-odds"]}</strong></span>}
                    </div>
                  );
                }
                if (game["value"]) {
                  gameSuggestion.push(
                    <div
                      key={"value-" + game["value"]}
                      className="game-spel-tips"
                    >
                      SPELFÖRSLAG: <strong>{game["value"]}</strong>{" "}
                    </div>
                  );
                }

                if (
                  event.results[game["team_a_participant_id"]] &&
                  event.results[game["team_a_participant_id"]]
                ) {
                  var team1Score =
                    event.results[game["team_a_participant_id"]] &&
                    event.results[game["team_a_participant_id"]].hasOwnProperty(
                      "runningScore"
                    )
                      ? event.results[game["team_a_participant_id"]]
                          .runningScore
                      : "";
                  var team2score =
                    event.results[game["team_b_participant_id"]] &&
                    event.results[game["team_b_participant_id"]].hasOwnProperty(
                      "runningScore"
                    )
                      ? event.results[game["team_b_participant_id"]]
                          .runningScore
                      : "";
                }
                let eventDate = dayjs(event.start_date).format("YYYY-MM-DD");
                let slugifiedTeamNames =
                  helpers.slugifyText(teamOneName) +
                  "-vs-" +
                  helpers.slugifyText(teamTwoName);
                let slugifiedTournamentName = helpers.slugifyText(game.league);
                var eventURL =
                  "/" +
                  event.eventid +
                  "/" +
                  (article.articleid ? article.articleid : 0) +
                  "/" +
                  config.mapSportSwedish[game["sport"]] +
                  "/" +
                  slugifiedTournamentName +
                  "/" +
                  slugifiedTeamNames +
                  "/" +
                  eventDate;
                mainContent.push(
                  <Link to={eventURL} key={game.eventid + gameCount}>
                    <EventData
                      tagContent={tagContent}
                      onlyForFirst={onlyForFirst}
                      game={game}
                      team1Score={team1Score}
                      team2score={team2score}
                      evenTime={evenTime}
                      totalGames={totalGames}
                      template={template}
                      expert={expert}
                      gameCount={gameCount}
                      teamOneName={teamOneName}
                      teamTwoName={teamTwoName}
                      team1Img={team1Img}
                      team2Img={team2Img}
                      event={event}
                      odds={odds}
                      gameSuggestion={gameSuggestion}
                      isStartpage={this.props.isStartpage}
                    />
                  </Link>
                );
                onlyForFirst = false;
              }
            }
          }
        }
      }
    }
    return mainContent;
  }

  render() {
    var content = this.getGames();
    if (content.length > 0) {
      return <div className="article-list">{content}</div>;
    } else {
      return null;
    }
  }
}
